/* eslint-disable no-extra-boolean-cast */
import moment from "moment";
import Cookies from "js-cookies";
import * as utils from "@kdshared/okta-utils";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
import { useState, useEffect, lazy, Suspense, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, List, Placeholder } from "semantic-ui-react";
import qs from "qs";
import Banner from "components/Banner";
import { setProfilePronunciation, getProfile } from "redux/actions/profile";
import ProfileImage from "components/common/ProfileImage";
import ProfileInfo from "components/ProfileInfo";
import ImpersonateUser from "components/ImpersonateUser";
import IconWithImageAndPopover from "components/common/IconWithImageAndPopover";
import Assistant from "components/common/Assistant";
import AssistantTo from "components/common/AssistantTo";
import Anonymized from "components/Anonymized";
import { fetchPronunciation } from "redux/actions/pronunciation";
import FullPageLoader from "components/FullPageLoader";

import IconWithText from "components/common/IconWithText";
import PhoneList from "components/common/PhoneList";
import EmailCopy from "components/common/EmailCopy";
import LABEL from "constants/label";
import Navigation from "components/Navigation";
import ScrollToTop from "components/Navigation/ScrollToTop";
import Clock from "components/common/Clock";
import PageLoading from "pages/PageLoading";
import Pill from "components/common/Pill";
import CONFIG from "constants/config";
import { SemanticToastContainer } from "react-semantic-toasts";
import {
  formatDateMonthDDYear,
  isIE,
  useQuery,
  // isSafari,
  // isMobileSafari,
  generateSLToken,
  isEmptyOrNull,
  formatDateMonth,
  getErrorDetails,
  createParamsObject
} from "helpers/utils";
import { getProfileImage } from "redux/actions/profileImage";
import { getProfileImageHighRes } from "redux/actions/profileImageHighRes";
import Alumni from "components/Alumni";
import authService from "auth/auth";
import sendEventData from "analytics/analytics";
import { EVENTS, TRIGGERS } from "analytics/constants";
import { BCGHeader, BCGFooter } from "@kdshared/global-nav";
import loadScript from "scripts/loadScript";
import BottomBar from "components/BottomBar";
import { getAchievements } from "redux/actions/achievements";
import Home from "pages/Home";
import ErrorPage from "pages/ErrorPage";
import { withErrorBoundary } from "components/ErrorBoundary";
import useCheckAPIError from "customHooks/useCheckAPIError";
import useOnScreenIntersection from "customHooks/useOnScreenIntersection";
import useFetchRecentCases from "customHooks/useFetchRecentCases";
import useFetchRecentMaterials from "customHooks/useFetchRecentMaterials";
import useMakeLinkedInQuery from "customHooks/useMakeLinkedInQuery";
import { getUserEmail, getUserHrId, isSessionExpired } from "auth/utils";
import { fetchOrganization, fetchUserDetails } from "components/App/handlers/handlers";
import { getEntitlements } from "redux/actions/entitlements";
import { getLinkedInData, getUserLinkedInStatus, initiateLinkedInConnect } from "redux/actions/linkedIn";
import LinkedInModal from "components/LinkedInModal";
import LinkedInFixedIcon from "components/LinkedInModal/LinkedInFixedIcon";
import { getImpersonatedUserImage } from "redux/actions/impersonateUserImage";
import useUpdateQueryParams from "customHooks/useUpdateQueryParams";
import SuccessNotificationModal from "components/LinkedInModal/NotificationModal/SuccessNotificationModal";
import FailureNotificationModal from "components/LinkedInModal/NotificationModal/FailureNotificationModal";
import { fetchCvBioDownloadDetails } from "redux/actions/cvBioDownloadDetails";
import {
  colleagueIcons,
  selfIcons,
  showLinkedInFeature,
  isUserAlumni,
  isRemindMeLater,
  removeCookie,
  isTokenExpired,
  getParsedCookie,
  autoDownloadExcel
} from "./helper/utils";

const Cases = lazy(() => import("pages/Cases"));
const Materials = lazy(() => import("pages/MaterialsPage"));
const AffiliationsPage = lazy(() => import("pages/AffiliationsPage"));
const {
  ACHIEVEMENTS: { KeyToPurpose },
  CASE_QUERY_PARAMS,
  MATERIAL_QUERY_PARAMS,
  COMMON: { OktaToken },
  CVBIO_EXCEL_FIELDSMAP
} = LABEL;

const { apiUrl, env, xApiKey, cookies, customLoggedInUserHrId, sharedConfig, notificationConfig } = CONFIG;

/**
 * App
 *
 * @param none
 *
 * @return Banner component items. Router pages
 */

function App() {
  const isBottomBarVisible = env !== "prod";
  const history = useHistory();
  const { pathname } = useLocation();
  const location = useLocation();
  const { achievements, impersonateUserImage } = useSelector((state) => state);
  const smartLogicToken = Cookies.getItem(cookies.SMART_LOGIC_TOKEN)
    ? JSON.parse(Cookies.getItem(cookies.SMART_LOGIC_TOKEN))
    : {};
  const currentDate = moment();
  const SLTokenExpiryDate = moment(smartLogicToken[".expires"]);
  const isSLTokenExpired = currentDate.diff(SLTokenExpiryDate, "days") >= 0;
  const keyToPurposeYears = achievements?.award?.filter(
    (awards) => awards?.name === KeyToPurpose || awards?.awardNameRefId === 23
  );

  const dispatch = useDispatch();

  // Retriving data from state
  const {
    error,
    profileError,
    userError,
    staffIdError,
    linkedInConnectFinalStatus,
    removeLinkedInAccessSuccess,
    linkedInUserStatus,
    linkedInConnectError
  } = useSelector((state) => state);
  const { profileLoading, userDataLoading } = useSelector((state) => state);
  const { profile, userData } = useSelector((state) => state);
  const { userEntitlements } = useSelector((state) => state);
  const { pronunciation } = useSelector((state) => state);
  const { profileImage } = useSelector((state) => state);
  const { casesLoading } = useSelector((state) => state);
  const { cvBioDownloadDetails, cvBioDownloadDetailsLoading } = useSelector((state) => state);
  const { peopleDataLoading, peopleData, peopleDataError } = useSelector((state) => state);
  const [showLeftNav] = useState(true);
  const [contactIcons, setContactIcons] = useState([]);
  const [showAlumni, setShowAlumni] = useState(false);
  const [hrEmpId, setHrEmpId] = useState(null);
  const [loggedInUserHrId, setLoggedInUserHrId] = useState(null);
  const [isEditable, setIsEditable] = useState(true);
  const [adobeScriptStatus, setAdobeScriptStatus] = useState(false);
  const [isEA, setIsEA] = useState(false);
  // For Error pages
  const [isErrorApi, setIsErrorApi] = useState(false);
  const query = useQuery(location.search);
  const urlQueryParams = qs.parse(location?.search, { ignoreQueryPrefix: true });
  const applicationSource = urlQueryParams[LABEL.COMMON.SOURCE_NAME];
  const [sourceApplicationName, setSourceApplicationName] = useState("");
  const { canEditColleagueProfile, canEditProfile, canImpersonate } = userEntitlements;
  const [errorDetails, setErrorDetails] = useState({});

  const [linkedInModalOpen, setLinkedInModalOpen] = useState(false);

  const { status, showLinkedinPopUp, showRenewConsent } = linkedInUserStatus;
  const [linkedInSuccessModalVisible, setLinkedInSuccessModalVisible] = useState(false);
  const [linkedInFailureModalVisible, setLinkedInFailureModalVisible] = useState(false);
  const [isUserSessionExpired, setIsUserSessionExpired] = useState(null);
  const { updateQueryParams, deleteQueryParam } = useUpdateQueryParams(location, history);

  useEffect(() => {
    if (isTokenExpired(getParsedCookie(OktaToken))) {
      removeCookie(OktaToken);
    }
  }, []);

  // handles the async operation to get the hrid from okta token.
  const fetchUserId = async () => {
    const id = await getUserHrId();
    return id;
  };

  useEffect(() => {
    if (customLoggedInUserHrId) {
      setLoggedInUserHrId(customLoggedInUserHrId);
    } else {
      fetchUserId().then((hrId) => {
        setLoggedInUserHrId(hrId);
      });
    }
  }, [fetchUserId]);

  const { linkedInQuery, linkedInCode, linkedInError } = useMakeLinkedInQuery();

  useEffect(() => {
    if (applicationSource) {
      const source = `${applicationSource ? `&source=${applicationSource}` : ""}`;
      setSourceApplicationName(source);
    }
  }, [applicationSource]);

  // Export to Excel for CV Bio details .

  useEffect(() => {
    // Check if the user's session is expired
    (async () => {
      const isExpired = await isSessionExpired();
      setIsUserSessionExpired(isExpired);
    })();
  }, []);

  const isDownloadExcel = query.get("download");

  //
  useEffect(() => {
    if (isUserSessionExpired === null) {
      return;
    }
    if (hrEmpId && isDownloadExcel && loggedInUserHrId === hrEmpId) {
      dispatch(
        fetchCvBioDownloadDetails({
          endpoint: `${apiUrl.linkApi}profile/downloaded-proposals-resumes/users/${hrEmpId}`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
  }, [isDownloadExcel, hrEmpId, isUserSessionExpired]);

  useEffect(() => {
    if (isDownloadExcel && !cvBioDownloadDetailsLoading) {
      autoDownloadExcel(cvBioDownloadDetails.data, CVBIO_EXCEL_FIELDSMAP, "MyExportData.xls", "download");
    }
  }, [cvBioDownloadDetails]);

  const section = query.get("edit");

  useEffect(() => {
    const hrid = query.get("hrid");

    if (!isErrorApi && !!loggedInUserHrId) {
      if (Number.isNaN(+hrid)) {
        setHrEmpId(hrid);
        setIsErrorApi(true);
        history.push(`/error?hrid=${hrid}`, { code: 404 });
      } else if (hrid) {
        setHrEmpId(hrid);
        if (history?.location?.pathname !== pathname) {
          updateQueryParams({ hrid, ...createParamsObject(location?.search) });
        }
      } else {
        setHrEmpId(loggedInUserHrId);
        updateQueryParams({ hrid: loggedInUserHrId, ...createParamsObject(location?.search) });
      }
    }
  }, [pathname, loggedInUserHrId, applicationSource]);

  //linked-in url
  useEffect(() => {
    if (hrEmpId && +hrEmpId === +loggedInUserHrId && section === "linked-in") {
      setLinkedInModalOpen(true); // Side effect
    }
    // Always ensure there's a side effect or state change
  }, [hrEmpId, section]);
  // check if impersonate user

  const isImpersonate = Cookies.getItem("impersonateUser");
  const impersonatedUserHrId = Cookies.getItem("impersonatedUserHrId");

  // Do the Impersonate User

  const impersonateUser = () => {
    if (isImpersonate) {
      document.body.click();
      return;
    }
    Cookies.setItem("impersonateUser", true);
    Cookies.setItem("impersonatedUserHrId", peopleData.id);
    window.location.reload();
  };

  useEffect(() => {
    if (
      !linkedInCode &&
      !linkedInError &&
      !linkedInConnectFinalStatus &&
      !removeLinkedInAccessSuccess &&
      (!status?.optin || (showRenewConsent && isRemindMeLater(3))) &&
      (showLinkedinPopUp || (showRenewConsent && isRemindMeLater(3))) &&
      +hrEmpId === +loggedInUserHrId &&
      showLinkedInFeature(peopleData) &&
      !isEmptyOrNull(peopleData)
    ) {
      setTimeout(() => {
        setLinkedInModalOpen(true);
      }, 3000);
    }
  }, [status, peopleData]);

  useEffect(() => {
    if (linkedInConnectFinalStatus?.code === 200) {
      setLinkedInSuccessModalVisible(true);
    }
  }, [linkedInConnectFinalStatus]);

  useEffect(() => {
    if (linkedInConnectError) {
      setLinkedInFailureModalVisible(true);
    }
  }, [linkedInConnectError]);

  useEffect(() => {
    if (linkedInQuery && hrEmpId) {
      dispatch(
        initiateLinkedInConnect({
          endpoint: `${apiUrl.linkApi}linkedin/login?hrId=${hrEmpId}&${linkedInQuery}`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
  }, [linkedInQuery, hrEmpId]);

  useEffect(() => {
    if (isImpersonate) {
      setIsEA(peopleData?.assistantIds?.includes(impersonatedUserHrId));
    } else {
      setIsEA(peopleData?.assistantIds?.includes(loggedInUserHrId));
    }
  }, [peopleData, loggedInUserHrId, impersonatedUserHrId]);

  useEffect(() => {
    window.onbeforeunload = isImpersonate;
    return () => {
      window.onbeforeunload = null;
    };
  });

  const setEditableStatus = (userId, hrId) => {
    if (userId === hrId) {
      setIsEditable(canEditProfile && !isUserAlumni(peopleData?.alumni));
    } else {
      setIsEditable((canEditColleagueProfile || isEA) && !isUserAlumni(peopleData?.alumni));
    }
  };

  useEffect(() => {
    if (loggedInUserHrId && peopleData?.id) {
      setEditableStatus(loggedInUserHrId, hrEmpId);
    }
    if (isImpersonate && peopleData?.id) {
      setEditableStatus(impersonatedUserHrId, hrEmpId);
    }
  }, [loggedInUserHrId, hrEmpId, canImpersonate, isEA, isImpersonate, impersonatedUserHrId, peopleData]);

  // fetch loggedin user details if loggedin user and profile id is not same
  useEffect(() => {
    const searchedUsers = [];
    if (
      !isEmptyOrNull(loggedInUserHrId) &&
      !isEmptyOrNull(hrEmpId) &&
      (loggedInUserHrId !== hrEmpId || impersonatedUserHrId)
    ) {
      searchedUsers.push(loggedInUserHrId.toString());
      if (impersonatedUserHrId) {
        searchedUsers.push(impersonatedUserHrId.toString());
      }
      fetchUserDetails(searchedUsers, dispatch);
    }
  }, [loggedInUserHrId, hrEmpId, dispatch]);

  const setIsErrorApiInt = (v) => {
    setIsErrorApi(v);
  };

  // For fetching Fresh smart logic token / refresh expired token

  useEffect(() => {
    if (!smartLogicToken.access_token || (smartLogicToken.access_token && isSLTokenExpired)) {
      generateSLToken();
    }
  }, []);

  // load required scripts
  useEffect(() => {
    if (navigator.userAgent.indexOf("MSIE") !== -1 || navigator.appVersion.indexOf("Trident/") > -1) {
      // scripts for IE Fix
      loadScript(process.env.REACT_APP_IE_POLYFILL, "iePolyfill");
      loadScript(process.env.REACT_APP_IE_ARRAY_FIX_POLYFILL, "ieArrayFixPolyfill");
    }
  }, []);

  useEffect(() => {
    if (profileError?.errorCode) {
      const hrid = query.get("hrid");
      setIsErrorApi(true);
      history.push(`/error?hrid=${hrid}`, { code: profileError?.errorCode });
    }
  }, [profileError]);

  const [setRef, hasIntersected] = useOnScreenIntersection({ rootMargin: "-43%" });
  const [setRefFooterPlaceholder, hasIntersectedWithExpertiseFooter] = useOnScreenIntersection({ rootMargin: "0px" });

  useEffect(() => {
    // page title
    document.title =
      (peopleData?.id && `Profiles - ${peopleData?.preferredFirstName || ""} ${peopleData?.preferredLastName || ""}`) ||
      "Profiles";
    // Global Variables to be used for Adobe Analytics
    window.$userEmail = getUserEmail();
    window.$profileName = `${peopleData?.preferredFirstName} ${peopleData?.preferredLastName}`;
    window.$profileTitle = peopleData?.businessTitle;
    window.$profileOffice = peopleData?.hostOfficeLocation?.name;

    if (peopleData?.id && !isErrorApi) {
      loadScript(process.env.REACT_APP_ADOBE_ANALYTICS_SCRIPT_URL, "adobeAnalytics", () => setAdobeScriptStatus(true));
    }
  }, [peopleData]);

  useEffect(() => {
    sendEventData(null, EVENTS.GLOBAL_RULE); // Analytics Event
  }, [adobeScriptStatus]);

  useEffect(() => {
    if (profile?.hrId) {
      dispatch(
        fetchPronunciation({
          endpoint: `${apiUrl.linkApi}profile/${hrEmpId}/audio`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
      dispatch(
        getLinkedInData({
          endpoint: `${apiUrl.linkApi}linkedin/${profile?.hrId}/data`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
      dispatch(
        getUserLinkedInStatus({
          endpoint: `${apiUrl.linkApi}linkedin/${profile?.hrId}/userOptinStatus`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
  }, [profile?.hrId]);

  // dispatch action to get the user profile details
  useEffect(() => {
    if (hrEmpId && !isErrorApi) {
      fetchOrganization(hrEmpId, dispatch);
      dispatch(
        getAchievements({
          endpoint: `${apiUrl.linkApi}profile/${hrEmpId}/awardscerts`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
      dispatch(
        getProfile({
          endpoint: `${apiUrl.linkApi}profile/${hrEmpId}/basic`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
      if (!isImpersonate) {
        dispatch(
          getEntitlements({
            endpoint: `${apiUrl.entitlementEngine}entitlement/users/public/entitlements?productId=4`,
            config: {
              headers: {
                "x-api-key": `${xApiKey.entitlementEngine}`
              }
            }
          })
        );
      }
      dispatch(
        getProfileImage({
          endpoint: `${apiUrl.peopleImage}${hrEmpId}`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.people}`,
              psid: utils.getPsId()
            },
            responseType: "arraybuffer"
          }
        })
      );
      if (impersonatedUserHrId) {
        dispatch(
          getImpersonatedUserImage({
            endpoint: `${apiUrl.peopleImage}${impersonatedUserHrId}`,
            config: {
              headers: {
                "x-api-key": `${xApiKey.people}`,
                psid: utils.getPsId()
              },
              responseType: "arraybuffer"
            }
          })
        );
      }
      dispatch(
        getProfileImageHighRes({
          endpoint: `${apiUrl.peopleImage}${hrEmpId}&mode=originalface`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.people}`,
              psid: utils.getPsId()
            },
            responseType: "arraybuffer"
          }
        })
      );
    } else if (isBottomBarVisible && !hrEmpId && !!loggedInUserHrId && !isErrorApi) {
      // this is required because of bottom bar (demo purposes)
      dispatch(
        getProfile({
          endpoint: `${apiUrl.linkApi}profile/${loggedInUserHrId}/basic`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.profile}`
            }
          }
        })
      );
    }
    window.scrollTo(0, 0);
  }, [dispatch, hrEmpId, isImpersonate]);

  useEffect(() => {
    if (impersonatedUserHrId) {
      dispatch(
        getEntitlements({
          endpoint: `${apiUrl.entitlementEngine}entitlement/users/${impersonatedUserHrId}/entitlements?productId=4`,
          config: {
            headers: {
              "x-api-key": `${xApiKey.entitlementEngine}`
            }
          }
        })
      );
    }
  }, [dispatch, impersonatedUserHrId]);

  useEffect(() => {
    dispatch(setProfilePronunciation({ id: pronunciation.audioId, url: pronunciation.audioPath }));
  }, [pronunciation]);

  // API Error handling
  useCheckAPIError(userError, profileError, staffIdError, error);

  // Recent Cases
  const { ssRecentCasesLoading, recentCases, totalCases } = useFetchRecentCases(profile, isErrorApi);

  // Recent Materials
  const { recentMaterialsLoading, recentMaterials, materialCount } = useFetchRecentMaterials(profile, isErrorApi);

  useEffect(() => {
    if (peopleData) setShowAlumni(peopleData?.alumni === "YES");
  }, [peopleData?.alumni]);

  useEffect(() => {
    if (loggedInUserHrId && hrEmpId && loggedInUserHrId === hrEmpId) {
      setContactIcons(selfIcons(<PhoneList phones={peopleData?.phones} />, peopleData));
      return;
    }
    setContactIcons(
      colleagueIcons(<PhoneList phones={peopleData?.phones} />, <EmailCopy profile={peopleData} />, peopleData)
    );
  }, [loggedInUserHrId, hrEmpId, peopleData]);

  useEffect(() => {
    if (!peopleDataLoading && !profileLoading && peopleData?.status && profile?.firstName) {
      setErrorDetails(
        getErrorDetails(
          { ...peopleData, ...profile },
          userData[loggedInUserHrId] ? userData[loggedInUserHrId] : peopleData
        )
      );
    }
  }, [userData, profile, peopleData, profileLoading, peopleDataLoading]);

  // Dispatching function from select box -- for demo

  const onChangeHandler = (value) => {
    const url = value
      ? `${pathname}?hrid=${value}${sourceApplicationName}`
      : `${pathname}?hrid=${loggedInUserHrId}${sourceApplicationName}`;
    history.push(url);
    setHrEmpId(value);
    window.location.reload();
  };

  // Show Cases or Materials on Profile Overview
  let showCases = true;
  if (
    !recentMaterialsLoading &&
    !ssRecentCasesLoading &&
    Object.keys(recentMaterials).length > 0 &&
    recentCases === undefined
  ) {
    showCases = false;
  }

  if (isIE()) {
    window.usabilla_live("hide");
    return (
      <Suspense fallback={<PageLoading />}>
        <ErrorPage code="IE11" showBtn={false} isError={setIsErrorApiInt} />
      </Suspense>
    );
  }

  const withBanner = (Component, props) => {
    return (
      <>
        {isImpersonate && (
          <ImpersonateUser
            user={userData[impersonatedUserHrId]}
            loading={userDataLoading}
            imgSrc={impersonateUserImage?.img || ""}
          />
        )}
        <Banner
          loggedInUserHrId={loggedInUserHrId}
          Anonymized={peopleData?.preferredFirstName?.toLowerCase() === "anonymized" ? <Anonymized /> : null}
          BannerImage={
            !profileImage?.img ? (
              <Placeholder className="banner-placeholder">
                <Placeholder.Image />
              </Placeholder>
            ) : (
              <ProfileImage imgSrc={profileImage?.img || ""} size="medium" />
            )
          }
          LOA={
            LABEL.BANNER.LOAStatus.includes(peopleData?.status) && (
              <Pill text={LABEL.BANNER.LOA} backgroundColor="#f39c13" />
            )
          }
          Alumni={showAlumni && <Alumni alumniSince={formatDateMonthDDYear(peopleData?.pastTerminationDate)} />}
          BannerHeader={
            (peopleDataLoading && (
              <>
                <Placeholder className="banner-heading-placeholder mt-2">
                  <Placeholder.Line />
                </Placeholder>
                <Placeholder className="banner-heading-placeholder mt-2">
                  <Placeholder.Line />
                </Placeholder>
              </>
            )) || (
              <ProfileInfo
                name={`${peopleData?.preferredFirstName || ""} ${peopleData?.preferredLastName || ""}`}
                localTitle={peopleData?.businessTitle || ""}
                hrId={peopleData?.id}
                audioId={profile?.audioId}
                loggedInUserHrId={loggedInUserHrId}
                keyToPurposeYears={
                  (keyToPurposeYears &&
                    keyToPurposeYears.length > 0 &&
                    keyToPurposeYears?.map((award) => moment(award?.dateReceived).format("YYYY"))) ||
                  []
                }
                audioUrl={profile?.audioUrl}
              />
            )
          }
          showEditWorkDayButton={loggedInUserHrId === hrEmpId || hrEmpId === impersonatedUserHrId}
          ContactIcons={contactIcons}
          SettingIcon={
            peopleData.alumni === "NO" &&
            canImpersonate &&
            peopleData.id !== loggedInUserHrId &&
            !isImpersonate && (
              <div data-tooltip={LABEL.BANNER.TOOLTIPS.Settings}>
                <IconWithImageAndPopover
                  size="medium"
                  imgSrc="/icons/setting.svg"
                  altText={LABEL.BANNER.Settings}
                  callback={() => {
                    sendEventData(TRIGGERS.BANNER.SETTINGS, EVENTS.BANNER_CLICKS); // Analytics Event
                  }}
                >
                  <List link className="popup__list">
                    {peopleData.alumni !== "YES" && peopleData.id !== loggedInUserHrId && canImpersonate && (
                      <List.Item
                        className="m-d-none"
                        key="91"
                        onClick={() => {
                          sendEventData(TRIGGERS.BANNER_SETTINGS.IMPERSONATE_USER, EVENTS.BANNER_CLICKS); // Analytics Event
                          impersonateUser();
                        }}
                      >
                        {LABEL.BANNER.ADMINLINKS.ImpersonateUser}
                      </List.Item>
                    )}
                  </List>
                </IconWithImageAndPopover>
              </div>
            )
          }
          BannerDetails={[
            peopleData?.hostOfficeLocation && (
              <Clock profile={peopleData?.hostOfficeLocation} profileV2Data={peopleData} />
            ),
            !peopleData && !peopleDataLoading ? (
              <Placeholder className="banner-meta-placeholder">
                <Placeholder.Line />
              </Placeholder>
            ) : (
              <IconWithText
                imgSrc="/icons/palm-salutation.svg"
                text={(peopleData && peopleData.salutation) || ""}
                labelText={`${LABEL.BANNER.Salutation}:`}
                altText={LABEL.BANNER.Salutation}
              />
            ),
            !peopleData?.latestHireDate ? (
              <Placeholder className="banner-meta-placeholder">
                <Placeholder.Line />
              </Placeholder>
            ) : (
              <IconWithText
                imgSrc="/icons/ic_calendar.svg"
                text={formatDateMonthDDYear(peopleData && peopleData?.latestHireDate, true) || ""}
                labelText={`${LABEL.BANNER.StartDate}:`}
                altText={LABEL.BANNER.StartDate}
              />
            ),

            <>
              {profile?.birthdateOptout ? (
                <div className="d-flex banner__birth-date">
                  <img src="/icons/birth_cake.svg" />
                  <img src="/icons/exclamation.svg" className="ml-h exclamation-icon" />
                  <span className="ml-h exclamation-text">{LABEL.BANNER.HiddenBirthDate}</span>
                </div>
              ) : (
                <IconWithText
                  imgSrc="/icons/birth_cake.svg"
                  labelText={`${LABEL.BANNER.BirthDate}:`}
                  text={
                    !profile?.birthdateOptout &&
                    peopleData?.birthDate &&
                    !isEmptyOrNull(peopleData.birthDate) &&
                    formatDateMonth(peopleData.birthDate)
                  }
                  altText={LABEL.BANNER.BirthDate}
                />
              )}
            </>
          ]}
          Assistant={
            peopleData?.assistants?.length > 0 &&
            !showAlumni && (
              <Assistant
                assistants={peopleData?.assistants?.filter((person) => person?.alumni?.toLowerCase() === "no")}
              />
            )
          }
          AssistantTo={
            !!peopleData?.assists &&
            !!peopleData?.assists.length && (
              <AssistantTo
                assistantTo={peopleData?.assists?.filter((person) => person?.alumni?.toLowerCase() === "no")}
              />
            )
          }
        />
        <Container as="section" className="pb-3 main-wrapper">
          <ScrollToTop />
          {showLeftNav && (
            <div className="left-nav-wrapper">
              <Navigation
                className="left-nav"
                userId={hrEmpId}
                caseCount={totalCases}
                materialCount={materialCount}
                hide={hasIntersected}
                sourceApplicationName={sourceApplicationName}
              />
            </div>
          )}
          <Component {...props} />
        </Container>
        {/* <ExpertiseFooter show /> */}
      </>
    );
  };

  const layout = (children) => {
    return (
      <>
        <BCGHeader
          environment={CONFIG.env === "local" ? "qa" : CONFIG.env}
          apiConfig={sharedConfig}
          notificationConfig={notificationConfig}
        />
        {children}
        <div ref={setRefFooterPlaceholder} />

        <div className="pb-3 position-relative mt-6" id="bcgfooter" ref={setRef}>
          <BCGFooter environment={CONFIG.env === "local" ? "qa" : CONFIG.env} apiConfig={sharedConfig} />
        </div>
      </>
    );
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <>
      <Security oktaAuth={authService} restoreOriginalUri={restoreOriginalUri}>
        <Suspense fallback={withBanner(PageLoading)}>
          <Switch>
            <SecureRoute exact path="/">
              <Redirect to="/overview" />
            </SecureRoute>
            <SecureRoute path="/overview">
              <>
                {peopleDataLoading ? (
                  <>{layout(<FullPageLoader />)}</>
                ) : (
                  <>
                    {errorDetails ? (
                      <>{layout(<ErrorPage showBtn={false} showSupportBtn {...errorDetails} />)}</>
                    ) : (
                      <>
                        {withErrorBoundary(
                          layout(
                            withBanner(Home, {
                              profile,
                              isProfileLoading: profileLoading,
                              recentCases,
                              recentMaterials,
                              ssRecentCasesLoading,
                              ssRecentMaterialsLoading: recentMaterialsLoading,
                              ssCasesLoading: ssRecentCasesLoading,
                              showCases,
                              showMetrics: true,
                              peopleDataError,
                              peopleDataLoading,
                              peopleData,
                              loggedInUserHrId,
                              isEditable,
                              setLinkedInModalOpen
                            })
                          )
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            </SecureRoute>
            <SecureRoute path="/cases" exact>
              <Redirect
                to={
                  isEmptyOrNull(query.get(CASE_QUERY_PARAMS.SORTING_ORDER)) &&
                  isEmptyOrNull(query.get(CASE_QUERY_PARAMS.VIEW_STATE)) &&
                  isEmptyOrNull(query.get(CASE_QUERY_PARAMS.ENABLE_AUTO_CORRECT)) &&
                  isEmptyOrNull(query.get(CASE_QUERY_PARAMS.RESULTS_PER_PAGE)) &&
                  isEmptyOrNull(query.get(CASE_QUERY_PARAMS.RESULTS_FROM_PAGE))
                    ? `/cases?hrid=${hrEmpId}&sortingOrder=dateOpened.desc&viewState=list&enableAutoCorrect=true&resultsPerPage=20&resultsFromPage=1${sourceApplicationName}`
                    : `${pathname}${location.search}`
                }
              />
              {peopleDataLoading ? (
                <>{layout(<FullPageLoader />)}</>
              ) : (
                <>
                  {errorDetails ? (
                    <>{layout(<ErrorPage showBtn={false} showSupportBtn {...errorDetails} />)}</>
                  ) : (
                    <>
                      {withErrorBoundary(
                        layout(
                          withBanner(Cases, {
                            profile,
                            casesLoading,
                            caseCount: totalCases,
                            loggedInUserHrId,
                            isEditable
                          })
                        )
                      )}
                    </>
                  )}
                </>
              )}
            </SecureRoute>
            <SecureRoute path="/materials" exact>
              <Redirect
                to={
                  isEmptyOrNull(query.get(MATERIAL_QUERY_PARAMS.SORTING_ORDER)) &&
                  isEmptyOrNull(query.get(MATERIAL_QUERY_PARAMS.VIEW_STATE)) &&
                  isEmptyOrNull(query.get(MATERIAL_QUERY_PARAMS.ENABLE_AUTO_CORRECT)) &&
                  isEmptyOrNull(query.get(MATERIAL_QUERY_PARAMS.RESULTS_PER_PAGE)) &&
                  isEmptyOrNull(query.get(MATERIAL_QUERY_PARAMS.RESULTS_FROM_PAGE))
                    ? `/materials?hrid=${hrEmpId}&sortingOrder=dateOpened.desc&viewState=list&enableAutoCorrect=true&resultsPerPage=20&resultsFromPage=1${sourceApplicationName}`
                    : `${pathname}${location.search}`
                }
              />
              {peopleDataLoading ? (
                <>{layout(<FullPageLoader />)}</>
              ) : (
                <>
                  {errorDetails ? (
                    <>{layout(<ErrorPage showBtn={false} showSupportBtn {...errorDetails} />)}</>
                  ) : (
                    <>
                      {withErrorBoundary(
                        layout(
                          withBanner(Materials, {
                            hrEmployeeId: profile?.hrId,
                            loggedInUserHrId,
                            isEditable,
                            profile,
                            isErrorApi,
                            materialCount
                          })
                        )
                      )}
                    </>
                  )}
                </>
              )}
            </SecureRoute>
            <SecureRoute path="/expertise" exact>
              {peopleDataLoading ? (
                <>{layout(<FullPageLoader />)}</>
              ) : (
                <>
                  {errorDetails ? (
                    <>{layout(<ErrorPage showBtn={false} showSupportBtn {...errorDetails} />)}</>
                  ) : (
                    <>
                      {withErrorBoundary(
                        layout(
                          withBanner(AffiliationsPage, {
                            peopleData,
                            hasIntersectedWithExpertiseFooter,
                            isEditable,
                            loggedInUserHrId,
                            profile
                          })
                        )
                      )}
                    </>
                  )}
                </>
              )}
            </SecureRoute>
            <Route path="/implicit/callback" component={LoginCallback} />
            <Route path="/error">
              <ErrorPage code={error?.response?.status} isError={setIsErrorApiInt} />
            </Route>
            <Route path="*">
              <ErrorPage code={404} isError={setIsErrorApiInt} />
            </Route>
          </Switch>
        </Suspense>
      </Security>

      {isBottomBarVisible && <BottomBar hrid={hrEmpId} onChangeHandler={onChangeHandler} />}
      <SemanticToastContainer />

      <LinkedInModal
        open={linkedInModalOpen}
        onModalClose={() => {
          deleteQueryParam({}, "edit");
          setLinkedInModalOpen(false);
        }}
        linkedInAlreadyOpted={status?.optin}
      />

      {!isErrorApi &&
        !!peopleData?.id &&
        +hrEmpId === +loggedInUserHrId &&
        (showLinkedInFeature(peopleData) || status?.optin) && (
          <LinkedInFixedIcon setLinkedInModalOpen={setLinkedInModalOpen} />
        )}

      {linkedInSuccessModalVisible && (
        <SuccessNotificationModal
          isOpen={linkedInSuccessModalVisible}
          onModalClose={() => {
            setLinkedInSuccessModalVisible(false);
            deleteQueryParam({ ...createParamsObject(location?.search) }, "code", "state");
          }}
        />
      )}
      {linkedInFailureModalVisible && (
        <FailureNotificationModal
          isOpen={linkedInFailureModalVisible}
          onModalClose={() => {
            setLinkedInFailureModalVisible(false);
            deleteQueryParam({ ...createParamsObject(location?.search) }, "error", "error_description");
          }}
        />
      )}
    </>
  );
}

export default App;
